<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                        
                <v-card
                    flat
                >
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Срок_действия_заявления") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-menu 
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="toDate | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker
                                        v-model="toDate"
                                        @input="dateMenu = false"
                                        color="teal"
                                        :min="min"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Список") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <div class="icon-click-row-group">

                                    <div @click="$refs.executionAttachments.click()">
                                        <input
                                            type="file"
                                            ref="executionAttachments"
                                            @change="onExecutionAttachmentsChange"
                                            style="display: none"
                                        />
                                        <v-icon small>
                                            fas fa-edit
                                        </v-icon>
                                    </div>
                                    
                                    <div class="cloud-file-list-box">
                                        <div class="cloud-items-container">

                                            <div v-if="!file" class="cfl-placeholder">{{ $t('Не_выбран_файл') }}</div>

                                            <v-row 
                                                v-else
                                                dense
                                            >
                                                <v-file-item
                                                    :name="file.name"
                                                    :size="file.size"
                                                    col-width="4"
                                                    :hide-load="true"
                                                />
                                        </v-row>

                                        </div>
                                    </div>                
                                    

                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="isOkVisible"
                >
                    {{ $t("Загрузить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import sys from '@/services/system';

export default {
    name: "ImportTemporaryVotersListDlg",
    data () {
        return {
            title: 'Загрузка_списка',
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            visible: false,
            loading: false,
            file: null,
            dateMenu: false,
            toDate: null,
            min: null,
            resolve: null,
            reject: null,
        }
    },
    computed: {
        isOkVisible() {
			return this.file != null;			
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    methods: {
        ...mapActions('global/attachments', ['validateFile']),
        async open(){
            this.toDate = this.$moment().add(1, 'd').format("YYYY-MM-DD");
            this.min = this.$moment().add(1, 'd').format("YYYY-MM-DD");
            this.file = null;
            this.visible = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async ok(){
            this.visible = false;
            this.resolve({ toDate: this.toDate, file: this.file });
        },
        async cancel(){
            this.visible = false;
            this.reject({ message: 'Cancelled' });
        },
        async onExecutionAttachmentsChange (event) {
            let files = Array.from(event.target.files);            
            this.file = null;
            let file = files[0];
            let checkResult = await this.validateFile(file);

            if (!checkResult.success)
                this.$notify.alert(this.$t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: file.name, reason: checkResult.message }));
            else 
                this.file = file;
            event.target.value = '';
        },
    }
}
</script>